<template>
  <div class="merlends">
    <div class="heads">
      <div class="fx">
        <el-image
          class="imgeds"
          :src="baseInfo.url"
          :preview-src-list="[baseInfo.url]"
        >
        </el-image>
        <div class="fx-1">
          <div class="contense hetxtd">
            <div>{{ baseInfo.applicant }}/{{ baseInfo.enterprise || "-" }}</div>
            <div class="btnds">{{ baseInfo.incomingStatusStr }}</div>
          </div>
          <div class="contense ontxtd">
            <div style="margin-right: 10px">
              采购商编号：{{ baseInfo.merchantCode || "-" }}
            </div>
            <div class="copyers" @click="copyed(baseInfo.merchantCode)">
              复制
            </div>
            <div>申请时间：{{ baseInfo.createTime }}</div>
            <div>授权时间：{{ baseInfo.creditTime }}</div>
            <div class="red">
              授权金额：¥{{ baseInfo.creditLimit || "0.00" }}
            </div>
          </div>
        </div>
      </div>
      <div style="margin-left: 20px; margin-bottom: -14px">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基础信息" name="one" />
          <el-tab-pane label="客户日志" name="two" />
        </el-tabs>
      </div>
    </div>
    <div v-show="activeName == 'one'">
      <div style="margin: 40px">
        <div style="margin-bottom: 40px">
          <div class="twotle">申请人信息</div>
          <div class="matxtd">
            <div class="cardse">申请姓名：{{ baseInfo.applicant }}</div>
            <div class="cardse">
              申请身份：{{ baseInfo.isLegalPerson == 1 ? "法人" : "非法人" }}
            </div>
            <div class="cardse">联系电话：{{ baseInfo.phone }}</div>
            <div class="cardse">关联店铺：{{ baseInfo.shopName }}</div>
            <div class="cardse">店铺类型：{{ baseInfo.shopTypeStr }}</div>
          </div>
        </div>
        <div style="margin-bottom: 40px">
          <div class="twotle">身份证信息</div>
          <div style="margin-top: 20px">
            <el-image
              class="imgcard"
              :src="baseInfo.frontOfIdcard"
              :preview-src-list="[baseInfo.frontOfIdcard]"
            >
            </el-image>
            <el-image
              class="imgcard"
              :src="baseInfo.backOfIdcard"
              :preview-src-list="[baseInfo.backOfIdcard]"
            >
            </el-image>
          </div>
          <div class="matxtd">
            <div class="cardse">姓名：{{ baseInfo.idCardName }}</div>
            <div class="cardse">
              <div class="contense">
                <div>身份证号：{{ baseInfo.idcardNo }}</div>
                <div
                  class="copyers"
                  style="margin-left: 10px"
                  @click="copyed(baseInfo.idcardNo)"
                >
                  复制
                </div>
              </div>
            </div>
            <div class="cardse">有效期：{{ baseInfo.idcardExpiryDate }}</div>
            <div class="cardse">住址：{{ baseInfo.idcardAddress }}</div>
          </div>
        </div>
        <div style="margin-bottom: 40px">
          <div class="twotle">证照信息</div>
          <div style="margin-top: 20px">
            <el-image
              v-for="(item, index) in baseInfo.photoAddress"
              class="imgcard"
              :src="item"
              :preview-src-list="[item]"
            >
            </el-image>
          </div>
          <!-- <div class="matxtd">
            <div class="cardse">单位名称：{{ baseInfo.companyName }}</div>
            <div class="cardse">
              <div class="contense">
                <div>证照编号：{{ baseInfo.companyNo }}</div>
                <div
                  class="copyers"
                  style="margin-left: 10px"
                  @click="copyed(baseInfo.companyNo)"
                >
                  复制
                </div>
              </div>
            </div>
            <div class="cardse">注册地址：{{ baseInfo.companyAddress }}</div>
            <div class="cardse">
              法人姓名：{{ baseInfo.companyLegalPerson }}
            </div>
            <div class="cardse">
              负责人姓名：{{ baseInfo.companyPrincipalPerson }}
            </div>
            <div class="cardse">注册时间：{{ baseInfo.companyRegTime }}</div>
            <div class="cardse">有效期：{{ baseInfo.companyExpiryDate }}</div>
          </div> -->
        </div>
        <div style="margin-bottom: 40px">
          <div class="twotle">授权信息</div>
          <div style="margin-top: 20px">
            <el-image
              class="imgcard"
              :src="baseInfo.companyAuthImg"
              :preview-src-list="[baseInfo.companyAuthImg]"
            >
            </el-image>
          </div>
        </div>
        <div style="margin-bottom: 40px">
          <div class="twotle">店铺照片</div>
          <div style="margin-top: 20px">
            <el-image
              class="imgcard"
              :src="baseInfo.companyEntranceImg"
              :preview-src-list="[baseInfo.companyEntranceImg]"
            >
            </el-image>
            <el-image
              class="imgcard"
              :src="baseInfo.companyCashierImg"
              :preview-src-list="[baseInfo.companyCashierImg]"
            >
            </el-image>
          </div>
        </div>
        <div style="margin-bottom: 40px">
          <div class="twotle">店铺信息</div>
          <div class="matxtd">
            <div class="cardse">经营面积：{{ baseInfo.companyArea }}平</div>
            <!-- <div class="cardse">xxx：xxx</div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-show="activeName == 'two'">
      <div style="margin: 20px">
        <el-table
          border
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F2F3F5' }"
        >
          <el-table-column
            align="center"
            label="序号"
            type="index"
            width="70"
          />
          <el-table-column align="center" label="日志时间" prop="createTime" />
          <el-table-column align="center" label="日志行为" prop="logTypeStr" />
          <el-table-column align="center" label="操作人" prop="operator" />
          <el-table-column align="center" label="备注" prop="remark" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  factoringCreditdetail, //授信客户详情
} from "@/api/apiAll/phpUrl.js";
export default {
  name: "",
  data() {
    return {
      id: "",
      activeName: "one",
      baseInfo: {
        url: "https://yygpro-oss.oss-cn-shenzhen.aliyuncs.com/images/notgoodspic.png",
      },
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.id = this.$route.query.id;
      let res = await factoringCreditdetail({ id: this.id });
      if (res.code == 200) {
        Object.assign(this.baseInfo, res.data.baseInfo);
        this.tableData = res.data.log;
      }
    },
    handleClick() {},
    copyed(txt) {
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = txt;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success("复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
  },
};
</script>
<style lang='scss' scoped>
/*tab样式*/
::v-deep .el-tabs__nav-wrap::after {
  background-color: #fff !important;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: 0px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #f6f6fa;
  border-radius: 5px;
}
.heads {
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px 20px 0px 20px;
  .imgeds {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
  .hetxtd {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    .btnds {
      margin-left: 10px;
      font-size: 12px;
      padding: 3px 5px;
      color: #666;
      border-radius: 5px;
      // background-color: #06b7ae;
      border: 1px solid #666;
    }
  }
  .ontxtd {
    margin-top: 20px;
    color: #666;
    div {
      margin-right: 50px;
    }
  }
}
.imgcard {
  width: 150px;
  height: 100px;
  margin-right: 20px;
}
.matxtd {
  .cardse {
    display: inline-block;
    width: 400px;
    color: #666;
    margin-top: 20px;
  }
}
.copyers {
  font-size: 12px;
  padding: 1px 3px;
  border: 1px solid #06b7ae;
  border-radius: 5px;
  color: #06b7ae;
  cursor: pointer;
}
</style>